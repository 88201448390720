import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F4F4F4',
      neutralLight2: '#D3D3D3',
      neutralDark4: '#777777',
      neutralDark1: '#1D2127',
      primaryLight1: '#0072BC',
      dangerLight1: '#EB5757',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Poppins', sans-serif",
    paragraph: "'Roboto', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
